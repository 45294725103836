import React from "react"
import '../styles/main.scss'

const Volunteer = () => {
  return (
    <div id="page">
      <main>
        <section id="hero" className="hero-small">
            <div className="container">
              <h1>Volunteer</h1>
              <p>June 30, 2022 - July 4, 2022</p>
            </div>
        </section>
      </main>
    </div>
  )
}

export default Volunteer